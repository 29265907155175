var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"light"},[_c('div',{staticClass:"container py-4"},[_c('h1',{staticClass:"h1 text-center",attrs:{"id":"pageHeaderTitle"}},[_vm._v("Location")]),_vm._m(0),_c('article',{staticClass:"postcard light red",class:{ expanded: _vm.isCardExpanded },attrs:{"data-aos":_vm.isCardExpanded ? '' : 'fade-up',"data-aos-delay":"20","data-aos-duration":"500"}},[_vm._m(1),_c('div',{staticClass:"postcard__text"},[_c('h1',{staticClass:"postcard__title red"},[_vm._v("Aroma")]),_vm._m(2),_c('div',{staticClass:"postcard__bar"}),_vm._m(3),_c('div',{staticClass:"postcard__tagbox"},[_vm._m(4),_c('div',{staticStyle:{"padding":"5px"}},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button"},on:{"click":_vm.handleCardExpand}},[_vm._v(" Menù ")])])]),(_vm.isCardExpanded)?_c('div',{staticClass:"postcard__expanded-text"},[_vm._m(5)]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"postcard light blue",attrs:{"data-aos":"fade-up","data-aos-delay":"20","data-aos-duration":"500"}},[_c('a',{staticClass:"postcard__img_link",attrs:{"href":"#"}},[_c('img',{staticClass:"postcard__img",attrs:{"src":require("../static/quirinale.jpg"),"alt":"Image Title"}})]),_c('div',{staticClass:"postcard__text"},[_c('h1',{staticClass:"postcard__title blue"},[_vm._v("Luogo cerimonia")]),_c('div',{staticClass:"postcard__subtitle small"},[_c('time',{attrs:{"datetime":"2024-09-27 18:00:00"}},[_c('i',{staticClass:"fas fa-calendar-alt mr-2"}),_vm._v("Ven, 27 settembre 2024 ore XXX ")])]),_c('div',{staticClass:"postcard__bar"}),_c('div',{staticClass:"postcard__preview-txt"},[_vm._v(" Il Palazzo del Quirinale (anche noto in epoca sabauda come Reggia del Quirinale e sotto i papi come Palazzo Apostolico del Quirinale o Palazzo Papale del Quirinale) è un palazzo storico di Roma, posto sull'omonimo colle e affacciato sull'omonima piazza; essendo dal 1870 la residenza ufficiale del Re d'Italia e dal 1946 del Presidente della Repubblica Italiana, è uno dei simboli dello Stato italiano. ")]),_c('div',{staticClass:"postcard__tagbox"},[_c('div',{staticStyle:{"padding":"5px"}},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"onclick":"window.open( `https://maps.app.goo.gl/UNfJZZuqzZBiaDqJ7`, '_blank');","type":"button"}},[_vm._v("Come arrivare")])]),_c('div',{staticStyle:{"padding":"5px"}},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"onclick":"window.open( `https://it.wikipedia.org/wiki/Palazzo_del_Quirinale`, '_blank');","type":"button"}},[_vm._v("Altre informazioni")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"postcard__img_link",attrs:{"href":"#"}},[_c('img',{staticClass:"postcard__img",attrs:{"src":require("../static/ristorante.jpg"),"alt":"Image Title"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"postcard__subtitle small"},[_c('time',{attrs:{"datetime":"2024-09-27 20:30:00"}},[_c('i',{staticClass:"fas fa-calendar-alt mr-2"}),_vm._v("Ven, 27 settembre 2024 ore XXX ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"postcard__preview-txt"},[_vm._v(" Una scenografia romantica ed incantevole, cornice perfetta per godere di una cucina di altissimo livello e di un servizio in sala attento alle esigenze più esclusive. "),_c('br'),_vm._v(" Presso il ristorante stellato Aroma che sorge sulla terrazza panoramica di Roma Aroma, all'ultimo piano dell'hotel 5 stelle Palazzo Manfredi e con vista sulla magnificenza del Colosseo, gli ospiti potranno intraprendere un viaggio nella cucina d'autore di Chef Giuseppe Di Iorio accompagnati dalle scelte del sommelier e dalle dolci invenzioni del pastry chef. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"5px"}},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"onclick":"window.open( `https://maps.app.goo.gl/rSZSe6xjJ6yLFQvg6`, '_blank');","type":"button"}},[_vm._v("Come arrivare")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"centered-list"},[_c('h1',[_c('b',[_vm._v("Menù")])]),_c('br'),_c('li',[_c('h4',[_c('b',[_vm._v("COCKTAIL DI BENVENUTO IN PIAZZA")])]),_c('h5',[_vm._v("Angolo del Casaro, Angolo del Norcino, Angolo del Mare e Show Cooking")])]),_c('li'),_c('br'),_c('li',[_c('h4',[_c('b',[_vm._v("PRIMO")])]),_c('h5',[_vm._v("Foglia al tartufo con taleggio")]),_c('h5',[_vm._v("Tortelloni ripieni di porcini")]),_c('h5',[_vm._v("Gnocchetti tricolore con pomodori datterino, olive taggiasche e guanciale croccante")])]),_c('br'),_c('li',[_c('h4',[_c('b',[_vm._v("SECONDO")])]),_c('h5',[_vm._v("Scalzato di Manzetta Prussiana con verdure in tempura e patate al forno")])]),_c('br'),_c('li',[_c('h4',[_c('b',[_vm._v("VINI")])]),_c('h5',[_vm._v("Selezione Colle Aluffi")])]),_c('br'),_c('li',[_c('h4',[_c('b',[_vm._v("DESSERT")])]),_c('h5',[_vm._v("Torta Nuziale al buffet di dolci e frutta")])])])
}]

export { render, staticRenderFns }